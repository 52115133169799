$(document).ready(function () {
    let $container_menu = $("#container_menu ");
    let $nav_bar = $("#nav_bar");
    let screenWidth = $(this).width();

    $nav_bar.find("#container_open_mobile_button").find("#openButtonMenuMovil").click(function (e) {
        showMenuMovil();
    });

    $container_menu.find('.container_close_mobile_menu').find('#closeButton').click(function (event) {
        hiddenMenuMovil();
        restartSubmenu();
    });

    $container_menu.find(".button_submenu").click(function (e) {
        e.preventDefault();
        let $container_submenu = $(this).parent().find('.container_submenu');
        restartSubmenu();

        if (screenWidth <= 768) {
            if ($container_submenu.hasClass("show")) {
                $container_submenu.removeClass('show');
                $(this).removeClass('active');
                return;
            }
        }

        $container_submenu.addClass('active-submenu');
        $container_submenu.addClass('show');
        $(this).addClass('active');
    });

    $container_menu.find(".scroll_link").click(function () {
        $container_menu.find('.container_submenu').removeClass('active-submenu');
        hiddenMenuMovil();
        restartSubmenu();
    });

    function restartSubmenu() {
        $container_menu.find('.container_submenu').removeClass('show').removeClass('active-submenu');
        $container_menu.find('.button_submenu').removeClass('active');
    }

    function hiddenMenuMovil() {
        $container_menu.removeClass('show');
        $nav_bar.removeClass('open_mobil_menu');
        unableScroll();
    }

    function showMenuMovil() {
        $container_menu.addClass('show');
        $nav_bar.addClass('open_mobil_menu');
        disableScroll();
    }

    function disableScroll() {
        $('html, body').css({
            overflow: 'hidden',
            height: '100%'
        });
    }

    function unableScroll() {
        $('html, body').css({
            overflow: 'auto',
            height: 'auto'
        });
    }
});